import React, {useEffect, useState} from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Button,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Text,
    CloseButton,
    Box,
    HStack,
    InputGroup,
    InputRightElement,
    Link,
    Flex,
    VStack, useToast, Image, ListItem, ListIcon, List, ModalFooter, useBreakpointValue
} from "@chakra-ui/react";
import ScanModal2 from "./ScanModal2";
import {FaCheckCircle} from "react-icons/fa";
import {FcRight} from "react-icons/fc";
import {useDispatch, useSelector} from "react-redux";
import {setIsNewFeatureOpen} from "../../../redux/reducers";
import axios from "axios";
import {SERVER_IP} from "../../../utils/constants";
import {setFeatureFlag} from "../../../utils/api";
import affiliateAnimation from "../../../pages/Affiliate/affiliate-animation.json";
import Lottie from "lottie-react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const ScanModalIntro1 = ({ isOpenScanModalIntro1, onCloseScanModalIntro1, onOpenScanModalIntro1 }) => {
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();
    const isNewFeatureOpen = useSelector(state => state.settings.isNewFeatureOpen)
    const dispatch = useDispatch()
    const breakpoint = useBreakpointValue({ base: 'base', sm: 'sm', md: 'md', lg: 'lg', xl: 'xl' });
    const isMobile = breakpoint === 'base' || breakpoint === 'sm';
    const navigate = useNavigate();
    const {t} = useTranslation();

    const closeModal = async () => {
        if (localStorage.getItem("userID") !== null) {
            onCloseScanModalIntro1();
            dispatch(setIsNewFeatureOpen(true))
            const setFeatureFlagResponse = await setFeatureFlag(localStorage.getItem('userID'),
                "show_user_rewardful_popup_june_3", true)
            console.log("setFeatureFlagResponse", setFeatureFlagResponse)
        } else {
            onCloseScanModalIntro1();
            localStorage.setItem("feature_flag", "true")
        }
    }

    const isOpenModal = () => {
        if (isMobile) {
            return false;
        }

        if (localStorage.getItem("userID") !== null) {
            return !isNewFeatureOpen
        } else {
            if (localStorage.getItem("feature_flag") === null) {
                return false
            } else {
                // console.log("GOT HERE")
                return localStorage.getItem("feature_flag") === "false"
            }
        }
    }

    return (
        <>
            <Modal isOpen={isOpenModal()} onClose={closeModal} isCentered size="xl">
                <ModalOverlay />
                <ModalContent borderRadius="1rem" p="0.75rem" h="37.5rem" w="35rem" overflowY="auto" position="relative">
                    <Box
                        position="absolute"
                        top="10px"
                        right="10px"
                        zIndex={999}
                    >
                        <CloseButton
                            size="md"
                            onClick={() => {
                                setIsLoading(true)
                                closeModal().then(r => {
                                    console.log("closeModal", r)
                                    setIsLoading(false)
                                });
                            }}
                            bg="transparent"
                            borderRadius="50%"
                            _hover={{
                                bg: "gray.200",
                                borderRadius: "50%",
                            }}
                        />
                    </Box>
                    <Flex alignItems="center" justifyContent="space-between" p={4} >
                        <VStack align="center" width={'100%'} spacing={6}>
                            <Text fontSize="1.18rem" fontWeight="bold">{t("Earn 60% referral commission!")}</Text>
                            <Box w={'full'} h={'1px'} bg={'gray.300'} />
                            <VStack align="center" width={'100%'} spacing={3}>
                                <List spacing={3} textAlign="start">
                                    <ListItem key={1}>
                                        <Flex align="start">
                                            <ListIcon as={FaCheckCircle} color="green.500" mt={1} mr={3} />
                                            <Text fontSize={'sm'}>{t("Sign up for our affiliate program and get your unique referral link.")}</Text>
                                        </Flex>
                                    </ListItem>
                                    <ListItem key={2}>
                                        <Flex align="start">
                                            <ListIcon as={FaCheckCircle} color="green.500" mt={1} mr={3} />
                                            <Text fontSize={"sm"}>{t("Share your referral link with your audience, colleagues, friends, family, or anyone else!")}</Text>
                                        </Flex>
                                    </ListItem>
                                    <ListItem key={2}>
                                        <Flex align="start">
                                            <ListIcon as={FaCheckCircle} color="green.500" mt={1} mr={3} />
                                            <Text fontSize={"sm"}>
                                                Earn a <Text as="span" fontWeight="bold">$500 BONUS</Text> for getting 15 referrals who stay for 3 months!
                                            </Text>
                                        </Flex>
                                    </ListItem>
                                    <ListItem key={2}>
                                        <Flex align="start">
                                            <ListIcon as={FaCheckCircle} color="green.500" mt={1} mr={3} />
                                            <Text fontSize={"sm"}>{t("We'll send you commission via PayPal on the 1st of every month.")}</Text>
                                        </Flex>
                                    </ListItem>
                                </List>
                            </VStack>
                        </VStack>
                    </Flex>
                    <ModalBody>
                        <HStack width={"100%"} bg={'white'} justify={'center'} align={'center'} mt={1}>
                            <Lottie
                                animationData={affiliateAnimation}
                                loop={true}
                                autoplay={true}
                                style={{ width: '61%', height: '61%' }}
                            />
                            {/*<FcRight size={'15rem'}/>*/}
                            {/*<Image*/}
                            {/*    src='/text.jpg'*/}
                            {/*    alt="text document"*/}
                            {/*    width="40%"*/}
                            {/*    height="100%"*/}
                            {/*    transform="scale(0.75)"*/}
                            {/*    objectFit="cover"*/}
                            {/*/>*/}
                        </HStack>
                    </ModalBody>
                    <ModalFooter>
                        <Flex width="100%" justify="center">
                            <Button
                                loadingText="Submitting"
                                size="lg"
                                isLoading={isLoading}
                                onClick={() => {
                                    setIsLoading(true)
                                    closeModal().then(r => {
                                        setIsLoading(false)
                                        navigate('/affiliate')
                                    })
                                }}
                                bgGradient={'linear(to-r, red.400,pink.400)'}
                                color={'white'}
                                width={'55%'}
                                _hover={{
                                    boxShadow: 'xl',
                                }}
                            >
                                {t("Start earning today")}
                            </Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default ScanModalIntro1;








