import {VStack, HStack, Box, Text, Image, Button, Icon, ListIcon, useBreakpointValue} from "@chakra-ui/react";
import { FaMicrophone, FaGlobe, FaShieldAlt, FaCheckCircle } from 'react-icons/fa';
import ReactPlayer from "react-player/lazy";
import React from "react";
import LazyLoad from "react-lazyload";
import {useAuth} from "../../redux/AuthProvider";
import URLInput from "./URLInput";

const TwoColumnRow = ({ title, description, features, buttonText, mediaSrc, mediaType, reverse, theFileInput,
                          onOpenSignUpScreen1, urlInput, setUrlInput, handleSubmit, currentPlaceholder, fade }) => {
    const breakpoint = useBreakpointValue({ base: 'base', sm: 'sm', md: 'md', lg: 'lg', xl: 'xl' });
    const isMobile = breakpoint === 'base' || breakpoint === 'sm' || breakpoint === 'md';
    const auth = useAuth();

    return (
        !isMobile ?
            <HStack justify="space-between" width="100%" flexDirection={reverse ? 'row-reverse' : 'row'} bg={'white'}>
                {/* Left side - Text content */}
                <VStack align="flex-start" spacing={5} width={reverse ? '45%' : '50%'} bg={'white'}>
                    <Text fontSize={'3xl'} fontWeight="bold" textAlign={'left'}>
                        {title}
                    </Text>
                    <Text fontSize={17.5} textAlign={'left'}>
                        {description}
                    </Text>
                    <VStack align="flex-start" spacing={3} mt={0} mb={3}>
                        {features.map((feature, index) => (
                            <HStack key={index}>
                                {/*<ListIcon as={FaCheckCircle} color="green.500" mt={1} mr={3} />*/}
                                <Icon as={FaCheckCircle} color="green.500" mt={0} mr={2.5} />
                                <Text fontSize={17} fontWeight={'600'} textAlign={'left'}>{feature.text}</Text>
                            </HStack>
                        ))}
                    </VStack>
                    {title === "Chat with YouTube videos." && auth.isLoggedIn ?
                        <Box width={'100%'}>
                            <Box width={'100%'}>
                                <URLInput
                                    urlInput={urlInput}
                                    setUrlInput={setUrlInput}
                                    handleSubmit={handleSubmit}
                                    currentPlaceholder={currentPlaceholder}
                                    fade={fade}
                                />
                            </Box>
                        </Box> :

                        <Button
                            borderRadius={12}
                            px={6}
                            py={6}
                            fontSize={18}
                            colorScheme={'orange'}
                            bgGradient="linear(to-r, orange.400, orange.500)"
                            _hover={{
                                bgGradient: "linear(to-r, orange.400, orange.600)", // Adjusts the gradient on hover
                                boxShadow: 'lg' // Adds a slight shadow on hover
                            }}
                            onClick={() => {
                                if (buttonText === "Get started") {
                                    onOpenSignUpScreen1();
                                } else {
                                    theFileInput.current.click();
                                }
                            }}>
                            {buttonText}
                        </Button>}
                </VStack>

                {/* Right side - Image/Graphic */}
                <Box width={reverse ? '50%' : '45%'} display={'flex'} alignItems={'center'} bg={'white'}>
                    {mediaType === "image" && title === "Chat with any PDF." ?
                            <Image src={mediaSrc} alt={title} width={'100%'} borderRadius={19}/>
                        :
                        mediaType === "image" ?
                            <Image src={mediaSrc} alt={title} width={'100%'} borderRadius={19}/>
                     : (
                        <Box width={'100%'}
                             borderRadius={20}
                             overflow="hidden">
                            <LazyLoad height={'auto'} width={'100%'} offset={100}>
                                <ReactPlayer
                                    url={mediaSrc}
                                    playing={true}
                                    loop={true}
                                    playbackRate={1}
                                    muted={true}
                                    controls={true}
                                    width={'100%'}
                                    height="auto"
                                />
                            </LazyLoad>
                        </Box>
                    )}
                </Box>
            </HStack> :










            <VStack width="100%" bg={'white'}>
                {/* Left side - Text content */}
                <VStack align="flex-start" spacing={5} width={'100%'} bg={'white'}>
                    <Text fontSize={'3xl'} fontWeight="bold" textAlign={'left'}>
                        {title}
                    </Text>
                    <Text fontSize={17.5} textAlign={'left'}>
                        {description}
                    </Text>
                    <VStack align="flex-start" spacing={3} mt={0} mb={3}>
                        {features.map((feature, index) => (
                            <HStack key={index}>
                                {/*<ListIcon as={FaCheckCircle} color="green.500" mt={1} mr={3} />*/}
                                <Icon as={FaCheckCircle} color="green.500" mt={0} mr={2.5} />
                                <Text fontSize={17} fontWeight={'600'} textAlign={'left'}>{feature.text}</Text>
                            </HStack>
                        ))}
                    </VStack>
                    {title === "Chat with YouTube videos." && auth.isLoggedIn ?
                        <Box width={'100%'}>
                            <Box width={'100%'}>
                                <URLInput
                                    urlInput={urlInput}
                                    setUrlInput={setUrlInput}
                                    handleSubmit={handleSubmit}
                                    currentPlaceholder={currentPlaceholder}
                                    fade={fade}
                                />
                            </Box>
                        </Box> :
                        <Button
                            borderRadius={12}
                            px={6}
                            py={6}
                            fontSize={18}
                            colorScheme={'orange'}
                            bgGradient="linear(to-r, orange.400, orange.500)"
                            _hover={{
                                bgGradient: "linear(to-r, orange.400, orange.600)", // Adjusts the gradient on hover
                                boxShadow: 'lg' // Adds a slight shadow on hover
                            }}
                            onClick={() => {
                                if (buttonText === "Get started") {
                                    onOpenSignUpScreen1();
                                } else {
                                    theFileInput.current.click();
                                }
                            }}>
                            {buttonText}
                        </Button>
                    }
                </VStack>

                {/* Right side - Image/Graphic */}
                <Box width={'100%'} display={'flex'} alignItems={'center'} bg={'white'} mt={8} borderRadius={20}>
                    {mediaType === "image" && title === "Chat with any PDF."  ?
                        <Image src={mediaSrc} alt={title} width={'100%'} borderRadius={19}/> :
                        mediaType === "image" ?
                            <Image src={mediaSrc} alt={title} width={'100%'} borderRadius={19}/>
                     : (
                        <Box width={'100%'}
                             borderRadius={20}
                             overflow="hidden">
                            <LazyLoad height={'auto'} width={'100%'} offset={100}>
                                <ReactPlayer
                                    url={mediaSrc}
                                    playing={true}
                                    loop={true}
                                    playbackRate={1}
                                    muted={true}
                                    controls={true}
                                    width={'100%'}
                                    height="auto"
                                />
                            </LazyLoad>
                        </Box>
                    )}
                </Box>
            </VStack>
    );
};

export default TwoColumnRow;
