import React, { useState } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Button,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Text,
    CloseButton,
    Box,
    HStack,
    InputGroup,
    InputRightElement,
    Link,
    Flex,
    VStack, useToast
} from "@chakra-ui/react";
import GoogleButton from "../../buttons/GoogleButton";
import SignInScreen2 from "./SignInScreen2";
import {handleSignIn, handleSignUp} from "../../../utils/auth-helpers";
import {useTranslation} from "react-i18next";

const SignInScreen1 = ({ isOpenSignInScreen1, onCloseSignInScreen1, onOpenSignUpScreen1 }) => {
    const [email, setEmail] = useState('');
    const [isOpenSignInScreen2, setIsOpenSignInScreen2] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();
    const {t} = useTranslation()

    const signin = async () => {
        if (email === '') {
            toast({
                title: t("Please enter a valid email address."),
                status: "error",
                duration: 5000,
                isClosable: true,
            })
            return;
        }

        setIsLoading(true)
        await handleSignIn(email.toLowerCase()).then((response) => {
            setIsLoading(false)
            if (response === null) {
                console.log("sign_in_error")
                toast({
                    title: t("Oops, something went wrong. Please try again."),
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
            } else if (response.status === 404) {
                toast({
                    title: t("Email doesn't exist. Please sign up first."),
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
            } else if (response.status === 200) {
                setIsOpenSignInScreen2(true);
                onCloseSignInScreen1();
                // setEmail('')
            }
        });
    };

    return (
        <>
            <Modal isOpen={isOpenSignInScreen1} onClose={onCloseSignInScreen1} isCentered size="xl">
                <ModalOverlay />
                <ModalContent borderRadius="1rem" p="0.75rem" h="28rem" w="26rem" overflowY="auto" position="relative">
                    <Box
                        position="absolute"
                        top="10px"
                        right="10px"
                        zIndex={999}
                    >
                        <CloseButton
                            size="md"
                            onClick={onCloseSignInScreen1}
                            bg="transparent"
                            borderRadius="50%"
                            _hover={{
                                bg: "gray.200",
                                borderRadius: "50%",
                            }}
                        />
                    </Box>
                    <Flex alignItems="center" justifyContent="space-between" p={4} >
                        <VStack align="center" width={'100%'} spacing={6}>
                            <Text fontSize="xl" fontWeight="bold">{t("Sign In")}</Text>
                            {/*<GoogleButton />*/}
                            {/*<HStack width={'100%'}>*/}
                            {/*    <Box w={'full'} h={'1px'} bg={'gray.300'} />*/}
                            {/*    <Text fontSize={'sm'} color={'gray.500'}>or</Text>*/}
                            {/*    <Box w={'full'} h={'1px'} bg={'gray.300'} />*/}
                            {/*</HStack>*/}
                        </VStack>
                    </Flex>
                    <ModalBody>
                        <Stack spacing={4} mt={6}>
                            <FormControl id="email" isRequired>
                                <FormLabel>{t("Email address")}</FormLabel>
                                <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                            </FormControl>
                            <Stack spacing={10} pt={2}>
                                <Button
                                    loadingText="Submitting"
                                    size="lg"
                                    isLoading={isLoading}
                                    bg={'orange.400'}
                                    color={'white'}
                                    onClick={signin}
                                    _hover={{
                                        bg: 'orange.500',
                                    }}>
                                    {t("Sign In")}
                                </Button>
                            </Stack>
                            <Stack pt={6}>
                                <Text align={'center'}>
                                    {t("Don't have an account?")} <Link color={'blue.400'} onClick={()=>{
                                    onCloseSignInScreen1();
                                    onOpenSignUpScreen1();
                                }}>{t("Sign Up")}</Link>
                                </Text>
                            </Stack>
                        </Stack>
                    </ModalBody>
                </ModalContent>
            </Modal>
            <SignInScreen2
                isOpen={isOpenSignInScreen2}
                onClose={() => setIsOpenSignInScreen2(false)}
                email={email}
            />
        </>
    );
};

export default SignInScreen1;








