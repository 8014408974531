import { Box, VStack, HStack, Image, Text, useBreakpointValue } from "@chakra-ui/react";

const BigTestimonial = () => {
    const breakpoint = useBreakpointValue({ base: 'base', sm: 'sm', md: 'md', lg: 'lg', xl: 'xl' });
    const isMobile = breakpoint === 'base' || breakpoint === 'sm' || breakpoint === 'md';

    return (
        <Box width={['84%','85%', '81%', '73%', '61%']} mx="auto" bg={'white'}>
            <VStack
                align={'center'}
                justify={'center'}
                spacing={12}
                flexDirection={isMobile ? "column" : "row"}
            >
                {/** When not in mobile, image on the left */}
                {!isMobile && (
                    <Image
                        src="/Yash_Headshot.jpeg"
                        alt="Yash Mehta Testimonial"
                        boxSize={['60px', '60px', '160px', '260px', '360px']}
                        borderRadius={20}
                        objectFit={'cover'}
                    />
                )}

                <Box flex="1">
                    <Text
                        fontSize={[20, 20, 20, 20, 21.5]}
                        fontWeight={600}
                        textAlign={"left"}
                    >
                        “After using Honeybear.ai quite a bit, I've noticed the responses are better quality than ChatGPT responses. Very impressive.”
                    </Text>
                    <HStack
                        mt={6}
                        align="center"
                        justify={"flex-start"}
                    >
                        {/** When in mobile, image on the left */}
                        {isMobile && (
                            <Image
                                borderRadius={20}
                                boxSize="5rem"
                                src={'/Yash_Headshot.jpeg'}
                                alt="Yash Mehta Testimonial Image"
                                objectFit="cover"
                                mr={4}
                            />

                        )}
                        <VStack align={"flex-start"} spacing={2}>
                            <Text fontWeight="bold" textAlign={'left'}>Yash Mehta</Text>
                            <Text fontSize={15} color="gray.600" textAlign={'left'}>
                                Jurassic Capital
                            </Text>
                        </VStack>
                    </HStack>
                </Box>
            </VStack>
        </Box>
    );
};

export default BigTestimonial;
