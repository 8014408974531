import React from 'react';
import {BrowserRouter as Router, Route, Routes, useLocation, useParams} from 'react-router-dom';
import WrapperComponent from "./pages/PDFPage/WrapperComponent";
import ThreeTierPricing from "./pages/Payments/ThreeTierPricing";
import {useAuth} from "./redux/AuthProvider";
import {useDispatch, useSelector} from "react-redux";
import {getFeatureFlag, setFeatureFlag} from "./utils/api";
import {setIsNewFeatureOpen} from "./redux/reducers";
import {useToast} from "@chakra-ui/react";
import DocumentGridGeneration from "./pages/HomePage/DocumentGridGeneration";
import WrapperComponentGeneration from "./pages/PDFPage/WrapperComponentGeneration";
import UnifiedHomePageGeneration from "./pages/HomePage/UnifiedHomePageGeneration";
import CheckoutResponse from "./pages/Payments/CheckoutResponse";
import Affiliate from "./pages/Affiliate/Affiliate";
import axios from "axios";
import {useTranslation} from "react-i18next";
import './i18n';
import WrapperComponentVideo from "./pages/PDFPage/WrapperComponentVideo";
import NewUnifiedHomePage from "./pages/HomePage/NewUnifiedHomePage"; // import the i18n configuration

function ReferralHandler() {
    const { referralCode } = useParams();

    React.useEffect(() => {
        if (referralCode) {
            // Store the referral code in localStorage
            localStorage.setItem("referralCode", referralCode);
            localStorage.setItem("referralCodeInfluencer", referralCode);
            console.log(`Referral code ${referralCode} stored in localStorage`);  // this runs for /u/code links
        }
        // Since we want the appearance of navigating to the home page without an actual redirect,
        // we don't need to use navigate() here. The component rendering is handled below.
    }, [referralCode]);

    // Render the same component as for the home page
    return <NewUnifiedHomePage />;
}

function CatchAllReferralHandler() {
    const location = useLocation();

    React.useEffect(() => {
        const referralCode = location.pathname.substring(1); // Remove the leading slash
        if (referralCode) {
            // Store the referral code in localStorage
            localStorage.setItem("referralCode", referralCode);
            localStorage.setItem("referralCodeInfluencer", referralCode);
            console.log(`Referral code ${referralCode} stored in localStorage`);
        }
    }, [location]);

    // Render the same component as for the home page or redirect as necessary
    return <NewUnifiedHomePage />;
}

function RootHandler() {
    const location = useLocation();

    React.useEffect(() => {
        try {
            const searchParams = new URLSearchParams(location.search);
            const viaParam = searchParams.get('via');

            if (viaParam === "teo") {
                // Perform the specific action based on the 'via' query parameter
                console.log(`URL contains via=${viaParam}`);
                localStorage.setItem("referralCodeInfluencer", viaParam);
            } else if (viaParam === "alyssa-tabling") {
                console.log(`URL contains via=${viaParam}`);
                localStorage.setItem("referralCodeInfluencer", viaParam);
            } else if (viaParam !== null) {
                localStorage.setItem("referralCode", viaParam);
            }
        } catch (error) {
            console.log('Error handling query parameters or localStorage:', error);
            // Optionally, handle the error, e.g., show a notification
        }
    }, [location]);

    return <NewUnifiedHomePage />;
}

function App() {
    const documents = useSelector(state => state.documents.savedDocuments);
    const [isLoading, setIsLoading] = React.useState(true);
    const auth = useAuth();
    const dispatch = useDispatch();
    const toast = useToast()
    const { i18n } = useTranslation();

    React.useEffect(() => {
        const fetchLocation = async () => {
            try {
                const response = await axios.get('https://api.country.is');
                if (response.data && response.data.country) {
                    const country = response.data.country;
                    console.log('Location country', country)
                    if (country === 'KR') {
                        i18n.changeLanguage('ko');
                    } else {
                        i18n.changeLanguage('en');
                    }
                }
            } catch (error) {
                console.error('Error fetching the location', error);
            }
        };

        fetchLocation();
    }, []);

    React.useEffect( () => {
// toast({
//             title: "We’re undergoing site maintenance",
//             description: "Scanned documents may not work properly. We'll be fully operational very soon. Thank you!",
//             status: "info",
//             duration: null,
//             isClosable: true,
//             position: "top",
//         });
        async function getFeatureFlagHere() {
            return await getFeatureFlag(localStorage.getItem("userID"), "show_user_rewardful_popup_june_3");
        }

        setIsLoading(true);
        if (localStorage.getItem("userID") !== null) {
            console.log("User is logged in");

            getFeatureFlagHere()
                .then(response => {
                    console.log("GET FEATURE FLAG RESPONSE", response);
                    if (response !== null) {
                        dispatch(setIsNewFeatureOpen(response.feature_flag))
                        setIsLoading(false);
                    } else {
                        dispatch(setIsNewFeatureOpen(false));
                        setFeatureFlag(localStorage.getItem("userID"), "show_user_rewardful_popup_june_3",
                            true).then(r => {console.log("Feature flag set to true", r)})
                        setIsLoading(false);
                    }
                })
                .catch(error => {
                    console.error("Error fetching feature flag", error);
                    setIsLoading(false)
                })
                .finally(() => {
                    setIsLoading(false);
                });

        } else {
            if (localStorage.getItem("feature_flag") === null) {
                localStorage.setItem("feature_flag", "false")
            }
            console.log("User is not logged in");
        }
        setIsLoading(false);
    }, []);

    return (
        isLoading ? <div>Loading...</div> :
            <Router>
                <Routes>
                    <Route path="/pdf/:id" element={<WrapperComponent /> } />
                    <Route path="/video/:id" element={<WrapperComponentVideo /> } />
                    <Route path="/" element={<RootHandler />} />
                    <Route path="/pricing" element={<ThreeTierPricing />} />
                    <Route path="/affiliate" element={<Affiliate />} />
                    <Route path="/generation/:id" element={<WrapperComponentGeneration /> } />
                    <Route path="/generation" element={<UnifiedHomePageGeneration />} />
                    <Route path="/checkout-response" element={<CheckoutResponse/>} />
                    <Route path="/u/:referralCode" element={<ReferralHandler />} />
                    <Route path="*" element={<CatchAllReferralHandler />} />
                </Routes>
            </Router>
    );
}

export default App;


