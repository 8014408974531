import React, { useState } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Button,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Text,
    CloseButton,
    Box,
    HStack,
    InputGroup,
    InputRightElement,
    Link,
    Flex,
    VStack, useToast
} from "@chakra-ui/react";
import GoogleButton from "../../buttons/GoogleButton";
import SignUpScreen2 from "./SignUpScreen2";
import {handleSignUp} from "../../../utils/auth-helpers";
import {useTranslation} from "react-i18next";
// import {GoogleLogin} from "@react-oauth/google";

const SignUpScreen1 = ({ isOpenSignUpScreen1, onCloseSignUpScreen1, onOpenSignInScreen1 }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [isOpenSignUpScreen2, setIsOpenSignUpScreen2] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();
    const {t} = useTranslation()

    const signup = async () => {
        if (firstName === '') {
            toast({
                title: t("First name is required."),
                status: "error",
                duration: 5000,
                isClosable: true,
            })
            return;
        }
        if (lastName === '') {
            toast({
                title: t("Last name is required."),
                status: "error",
                duration: 5000,
                isClosable: true,
            })
            return;
        }
        if (email === '') {
            toast({
                title: t("Please enter a valid email address."),
                status: "error",
                duration: 5000,
                isClosable: true,
            })
            return;
        }
        if (!email.includes('@')) {
            toast({
                title: t("Enter a valid email address with an @ symbol"),
                status: "error",
                duration: 5000,
                isClosable: true,
            })
            return;
        }

        const userName = email;
        setIsLoading(true)
        await handleSignUp(email.toLowerCase(), userName, firstName, lastName).then((response) => {
            setIsLoading(false)
            if (response === null) {
                console.log("sign_up_error")
                toast({
                    title: t("Oops, something went wrong. Please try again."),
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
            } else if (response.status === 400) {
                toast({
                    title: t("Email already exists. Please try again."),
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
            } else if (response.status === 401) {
                toast({
                    title: t("The account with this email was deleted by the user"),
                    description: t("We prevent deleted accounts from signing up again. Please email contact@honeybear.ai if you have questions."),
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                })
            }
            else if (response.status === 200) {
                setIsOpenSignUpScreen2(true);
                onCloseSignUpScreen1();
                // setEmail('')
                // setFirstName('')
                // setLastName('')
            }
        });
    };

    // const handleLoginSuccess = (response) => {
    //     console.log('Login Success:', response);
    //     // Send response token to your backend for further processing
    // };
    //
    // const handleLoginFailure = (response) => {
    //     console.log('Login Failed:', response);
    // };

    return (
        <>
            <Modal isOpen={isOpenSignUpScreen1} onClose={onCloseSignUpScreen1} isCentered size="xl">
                <ModalOverlay />
                <ModalContent borderRadius="1rem" p="0.75rem" h="30.5rem" w="26rem" overflowY="auto" position="relative">
                    <Box
                        position="absolute"
                        top="10px"
                        right="10px"
                        zIndex={999}
                    >
                        <CloseButton
                            size="md"
                            onClick={onCloseSignUpScreen1}
                            bg="transparent"
                            borderRadius="50%"
                            _hover={{
                                bg: "gray.200",
                                borderRadius: "50%",
                            }}
                        />
                    </Box>
                    <Flex alignItems="center" justifyContent="space-between" p={4} >
                        <VStack align="center" width={'100%'} spacing={6}>
                            <Text fontSize="1.18rem" fontWeight="bold">{t("Sign Up")}</Text>
                            {/*<GoogleLogin*/}
                            {/*    onSuccess={handleLoginSuccess}*/}
                            {/*    onError={handleLoginFailure}*/}
                            {/*    buttonText="Sign up with Google"*/}
                            {/*/>*/}
                            {/*<HStack width={'100%'}>*/}
                            {/*    <Box w={'full'} h={'1px'} bg={'gray.300'} />*/}
                            {/*    <Text fontSize={'sm'} color={'gray.500'}>or</Text>*/}
                            {/*    <Box w={'full'} h={'1px'} bg={'gray.300'} />*/}
                            {/*</HStack>*/}
                        </VStack>
                    </Flex>
                    <ModalBody>
                        <Stack spacing={4} mt={6}>
                            <HStack>
                                <Box>
                                    <FormControl id="firstName" isRequired>
                                        <FormLabel>{t("First Name")}</FormLabel>
                                        <Input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl id="lastName" isRequired>
                                        <FormLabel>{t("Last Name")}</FormLabel>
                                        <Input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)}/>
                                    </FormControl>
                                </Box>
                            </HStack>
                            <FormControl id="email" isRequired>
                                <FormLabel>{t("Email address")}</FormLabel>
                                <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                            </FormControl>
                            <div style={{justifyContent:'center', alignItems:'center', width:'100%', display:'flex'}}>
                                <Text align={'center'} fontSize={12} mt={2} bg={'white'} width={'85%'}>
                                    {t("By signing up, you agree to our")}
                                    <Link as="a" href="https://docs.google.com/document/d/1DXpWIW7B0ebKCHqfPlmnumI0qIoernblc5KUvZ1k79w/edit" color="blue.400" isExternal
                                          fontSize={12} fontWeight={'bold'}> {t("Terms of Service")} </Link>
                                    {t("and")}
                                    <Link as="a" href="https://docs.google.com/document/d/1X4_5lAZoPksg_gppFKMTWvIdzBP5oypkaeMuPBbRU9k/edit" color="blue.400" isExternal
                                          fontSize={12} fontWeight={'bold'}> {t("Privacy Policy")}</Link>
                                </Text>
                            </div>

                            <Stack spacing={10} pt={2}>
                                <Button
                                    loadingText="Submitting"
                                    size="lg"
                                    isLoading={isLoading}
                                    bg={'orange.400'}
                                    color={'white'}
                                    onClick={signup}
                                    _hover={{
                                        bg: 'orange.500',
                                    }}>
                                    {t("Sign Up")}
                                </Button>
                            </Stack>
                            <Stack pt={4}>
                                <Text align={'center'}>
                                    {t("Already a user?")} <Link color={'blue.400'} onClick={()=>{
                                    onCloseSignUpScreen1();
                                    onOpenSignInScreen1();
                                }}>{t("Sign In")}</Link>
                                </Text>
                            </Stack>
                        </Stack>
                    </ModalBody>
                </ModalContent>
            </Modal>
            <SignUpScreen2
                isOpen={isOpenSignUpScreen2}
                onClose={() => setIsOpenSignUpScreen2(false)}
                email={email}
                firstName={firstName}
                lastName={lastName}
            />
        </>
    );
};

export default SignUpScreen1;








